import React from 'react';
import StoreButton from './StoreButton';

import GooglePlayLogo from 'assets/icons/google-play-logo.svg';

const GooglePlayButton = ({ ...props }) => {
  const handleLink = (e: Event) => {
    e.preventDefault();
    window.open(
      'https://play.google.com/store/apps/details?id=com.effecto.app',
      '_blank',
    );
  };

  return (
    <StoreButton
      onClick={handleLink}
      downloadText="Get it On"
      storeTitle="Google Play"
      Icon={GooglePlayLogo}
      {...props}
    />
  );
};

export default GooglePlayButton;
