import React, { FC } from 'react';
import styled from 'styled-components';

import { Text } from 'components';

interface StoreButtonProps {
  onClick: () => void;
  downloadText: string;
  storeTitle: string;
  Icon: string;
}

const Container = styled.button`
  background-color: ${({ theme }) => theme.colors.dark100};
  border-radius: 4px;
  display: flex;
  align-items: center;
  max-width: 10rem;
  width: 100%;
  padding: 0.6rem 0rem 0.6rem 1rem;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.light100};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  align-items: flex-start;
`;

const DownloadText = styled(Text)``;

const StoreTitle = styled(Text)``;

const StoreButton: FC<StoreButtonProps> = ({
  downloadText,
  storeTitle,
  Icon,
  onClick,
  ...props
}) => (
  <Container onClick={onClick} {...props}>
    <Icon />
    <TextContainer>
      <DownloadText type="bodyS" color="light100">
        {downloadText}
      </DownloadText>
      <StoreTitle type="buttonTextM" color="light100">
        {storeTitle}
      </StoreTitle>
    </TextContainer>
  </Container>
);

export default StoreButton;
